<template>
	<a-card :title="`消息通知(${pageUnreadCount}/${pageCount})`" :bordered="false">
        <div v-if="loading" class="notice-loading">
            <a-spin size="large" tip="加载中，请稍后" />    
        </div>
        <template v-else>
            <a-collapse v-if="noticeData.length" class="notice-collapse" expand-icon-position="right" :destroy-inactive-panel="true" :bordered="false" @change="collapseChange">
                <a-collapse-panel class="notice-panel" v-for="(item, index) in noticeData" :key="index">
                    <div class="notice-panel__header" slot="header">
                        <span class="notice-panel__status" :class="{'notice-panel__status--unread': item.is_read == 0}"></span>
                        <span class="notice-panel__title">{{item.title}}</span>
                        <span class="notice-panel__time">{{formatTime(item.update_time)}}</span>
                    </div>
                    <div class="notice-panel__content" v-html="item.content"></div>
                </a-collapse-panel>
            </a-collapse>
            <a-empty v-else class="notice-empty" description="当前没有通知消息~">
            </a-empty>
        </template>
        <a-pagination v-if="pageCount > pageLimit" class="notice-page" v-model="pageCurrent" :total="pageCount" show-less-items @change="pageChange" />
	</a-card>
</template>

<script>
import { formatTime } from '@/common/date';
import { mapState } from "vuex";
export default {
    data() {
        return {
            noticeData: [],
            pageCurrent: 1,
            loading: true
        }
    },
    computed: {
        ...mapState({
            pageLimit: state => state.notice.limit,
            pageUnreadCount: state => state.notice.unreadCount,
            pageCount: state => state.notice.count,
        })
	},
    created() {
        
    },
    mounted() {
        this.getMessageList(this.pageCurrent);
    },
    methods: {
        // 获取列表
        getMessageList(page) {
            this.loading = true;
            this.$store.dispatch('notice/getList', {page}).then(res => {
                this.noticeData = res;
            }).catch((err) => {
                // 更新失败
                this.$message.error({ content: err.msg, key: 'message' });
            }).finally(() => {
                this.loading = false;
            })
        },
        // 格式化时间
        formatTime(timestamp, format) {
            const thisYear = new Date().getFullYear();
            format = thisYear == formatTime(timestamp * 1000, 'Y') ? 'M月D日 H:I' : 'Y年M月D日 H:I';
            return formatTime(timestamp * 1000, format);
        },
        // 标记已读
        collapseChange(key) {
            let keyArr = key;
            const noticeData = this.noticeData;
            for(let i = 0, max = keyArr.length; i < max; i++) {
                if(noticeData[keyArr[i]].is_read == 0) {
                    noticeData[keyArr[i]].is_read = 1;
                    this.$store.dispatch('notice/reportRead', {
                        id: noticeData[keyArr[i]].id,
                        page: this.pageCurrent
                    });
                }
            }
        },
        // 分页切换
        pageChange(page) {
            this.getMessageList(page);
        }
    }
};
</script>

<style lang="less" scoped>
// 空数据
.notice-empty{
    padding: 180px 0;
}
// 进入加载状态
.notice-loading{
    text-align: center;
    padding: 100px 0;
    height: 320px;
}
.notice-collapse{
    background-color: transparent;
    /deep/ .ant-collapse-item{
        border-bottom: 1px solid #f0f0f0;
    }
}
.notice-panel{
    &__header{
        padding: 0 240px 0 20px;
        position: relative;
    }
    &__status{
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #d8d8d8;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        &--unread{
            background-color: #dc4e26;
        }
    }
    &__title{
        position: relative;
    }
    &__time{
        position: absolute;
        color: #999;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &__content{
        /deep/ p{
            margin-bottom: 10px;
        }
    }
}
// 分页
.notice-page{
    margin-top: 20px;
    text-align: center;
}
</style>
